<template>
  <div>
    <div class="container">
      <div class="flex flex-row items-center mb-6" style="margin-top: 80px;">
        <div class="flex flex-row items-center w-1/2">
          <div class="border-red-600 border-r-4 h-10 w-1 mr-2"></div>
          <div class="sm:text-lg md:text-2xl lg:text-3xl font-bold">
            ความรู้ด้านเทคโนโลยี : สื่อดิจิทัล วิดีโอ มัลติมีเดีย
          </div>
        </div>
      </div>

      <div class="title">
        ผลงานประกวดโครงการ Software Park - WealthMagik เงินออมสร้างชาติ Awards
        Season 2
      </div>

      <div>
        <div class="subTitle mb-4">ประเภทนักเรียน</div>
        <div class="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-2 mb-12">
          <div v-for="(item, index) in items1" :key="index" class="mb-12">
            <iframe
              width="100%"
              height="100%"
              :src="`${item.linkYoutube}`"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>

            <div class="flex flex-row">
              <div>
                <div class="circleImg">
                  <img class="object-contain border-1" style="border-radius: 100%;" src="@/assets/mog/icon.jpg" alt="">
                </div>
              </div>

              <div class="content flex items-center" style="margin-top: 10px;">
                {{item.description}}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="subTitle mb-4">ประเภทบุคคลทั่วไป</div>
        <div class="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-2 mb-12">
          <div v-for="(item, index) in items2" :key="index" class="mb-12">
            <iframe
              width="100%"
              height="100%"
              :src="`${item.linkYoutube}`"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>

            <div class="flex flex-row">
              <div>
                <div class="circleImg">
                  <img class="object-contain border-1" style="border-radius: 100%;" src="@/assets/mog/icon.jpg" alt="">
                </div>
              </div>

              <div class="content flex items-center" style="margin-top: 10px;">
                {{item.description}}
              </div>
            </div>
          </div>
        </div>
        <hr style="margin-top: 40px; margin-bottom: 30px; border: 2px solid #E6E6E6;">
      </div>

      <div>
        <div class="subTitle mb-4">ดิจิทัลชุมชน</div>
        <div class="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-2 mb-12">
          <div v-for="(item, index) in items3" :key="index" class="mb-12">
            <iframe
              width="100%"
              height="100%"
              :src="`${item.linkYoutube}`"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>

            <div class="flex flex-row">
              <div>
                <div class="circleImg">
                  <img class="object-contain border-1" style="border-radius: 100%;" src="@/assets/mog/icon.jpg" alt="">
                </div>
              </div>

              <div class="content flex items-center" style="margin-top: 10px;">
                {{item.description}}
              </div>
            </div>
          </div>
        </div>
        <hr style="margin-top: 40px; margin-bottom: 30px; border: 2px solid #E6E6E6;">
      </div>

      <div>
        <div class="subTitle mb-4">Thailand 4.0</div>
        <div class="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-2 mb-12">
          <div v-for="(item, index) in items4" :key="index" class="mb-12">
            <iframe
              width="100%"
              height="100%"
              :src="`${item.linkYoutube}`"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>

            <div class="flex flex-row">
              <div>
                <div class="circleImg">
                  <img class="object-contain border-1" style="border-radius: 100%;" src="@/assets/mog/icon.jpg" alt="">
                </div>
              </div>

              <div class="content flex items-center" style="margin-top: 10px;">
                {{item.description}}
              </div>
            </div>
          </div>
        </div>
        <hr style="margin-top: 40px; margin-bottom: 30px; border: 2px solid #E6E6E6;">
      </div>

      <div>
        <div class="subTitle mb-4">Business Transformation</div>
        <div class="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-2 mb-12">
          <div v-for="(item, index) in items5" :key="index" class="mb-12">
            <iframe
              width="100%"
              height="100%"
              :src="`${item.linkYoutube}`"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>

            <div class="flex flex-row">
              <div>
                <div class="circleImg">
                  <img class="object-contain border-1" style="border-radius: 100%;" src="@/assets/mog/icon.jpg" alt="">
                </div>
              </div>

              <div class="content flex items-center" style="margin-top: 10px;">
                {{item.description}}
              </div>
            </div>
          </div>
        </div>
        <hr style="margin-top: 40px; margin-bottom: 30px; border: 2px solid #E6E6E6;">
      </div>

      <div>
        <div class="subTitle mb-4">ตัวชี้วัดทางเศรษฐกิจดิจิทัล</div>
        <div class="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-2 mb-12">
          <div v-for="(item, index) in items6" :key="index" class="mb-12">
            <iframe
              width="100%"
              height="100%"
              :src="`${item.linkYoutube}`"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>

            <div class="flex flex-row">
              <div>
                <div class="circleImg">
                  <img class="object-contain border-1" style="border-radius: 100%;" src="@/assets/mog/icon.jpg" alt="">
                </div>
              </div>

              <div class="content flex items-center" style="margin-top: 10px;">
                {{item.description}}
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items1: [
        {
          linkYoutube: 'https://www.youtube.com/embed/mJklazD2hqU',
          description: '01 ชนะเลิศ เรื่อง หาเงินง่าย จะตาย',
        },
        {
          linkYoutube: 'https://www.youtube.com/embed/mJklazD2hqU',
          description: '02 รองชนะเลิศอันดับ 1 เรื่อง จากวันนั้น ถึงวันนี้',
        },
        {
          linkYoutube: 'https://www.youtube.com/embed/mJklazD2hqU',
          description: '03 รองชนะเลิศอันดับ 2 Future',
        },
        {
          linkYoutube: 'https://www.youtube.com/embed/mJklazD2hqU',
          description: '04 Popular Vote เรื่อง เส้นทางของดุ่ย',
        },
      ],

      items2: [
        {
          linkYoutube: 'https://www.youtube.com/embed/mJklazD2hqU',
          description: '01 ชนะเลิศ Popular Vote เรื่อง Seed',
        },
        {
          linkYoutube: 'https://www.youtube.com/embed/mJklazD2hqU',
          description: '02 รองชนะเลิศอันดับ 2 เรื่อง ง่ายมากกก',
        },
        {
          linkYoutube: 'https://www.youtube.com/embed/mJklazD2hqU',
          description: '03 รองชนะเลิศอันดับ 3 เรื่อง Your New Weapon',
        },
      ],

      items3: [
        {
          linkYoutube: 'https://www.youtube.com/embed/mJklazD2hqU',
          description: 'Viral marketing การตลาดแบบไวรัส',
        },
        {
          linkYoutube: 'https://www.youtube.com/embed/mJklazD2hqU',
          description: 'ติดปีกให้ธึรกิจ SME ในยุคดิจิทัล',
        },
        {
          linkYoutube: 'https://www.youtube.com/embed/mJklazD2hqU',
          description: 'เคล็ดลับการตั้งชื่อเว็บไซต์',
        },
        {
          linkYoutube: 'https://www.youtube.com/embed/mJklazD2hqU',
          description: 'Mobile first คืออะไร',
        },
      ],

      items4: [
        {
          linkYoutube: 'https://www.youtube.com/embed/mJklazD2hqU',
          description: 'VTR สดช 1',
        },
        {
          linkYoutube: 'https://www.youtube.com/embed/mJklazD2hqU',
          description: 'สกู๊ปเน็ตประชารัฐ ดร.พิเชฐ ดุรงคเวโรจน์',
        },
        {
          linkYoutube: 'https://www.youtube.com/embed/mJklazD2hqU',
          description: 'ภารกิจกระทรวงดิจิทัลฯ สู่ยุคไทยแลนด์ 4.0.....',
        },
      ],

      items5: [
        {
          linkYoutube: 'https://www.youtube.com/embed/mJklazD2hqU',
          description: 'VTR สดช 1',
        },
        {
          linkYoutube: 'https://www.youtube.com/embed/mJklazD2hqU',
          description: 'BusinessTransformation on Digital Economy',
        },
        {
          linkYoutube: 'https://www.youtube.com/embed/mJklazD2hqU',
          description: 'VDO Infographic โครงการ B2B',
        },
      ],

      items6: [
        {
          linkYoutube: 'https://www.youtube.com/embed/mJklazD2hqU',
          description: 'ผลการศึกษาเบื้องต้นของตัวชี้วัดทางเศรษฐกิจดิจิทัล',
        },
      ],

    }
  }
};
</script>

<style scoped>
.container {
  font-family: KanitFont;
}

.title {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  color: #3d3d3d;
}

.subTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  color: #3d3d3d;
}

.circleImg {
  width: 56px;
  height: 56px;
  border: 1px solid #c6c6c6;
  box-sizing: border-box;
  border-radius: 100%;
  margin-top: 10px;
  margin-right: 5px;
}

.content {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
}
</style>